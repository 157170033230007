<template>
  <div class="caddy">
    <div class="banner-top">
      <div class="banner-top__bg">
        <img :src="data?.dich_vu_caddy?.general" alt="" />
      </div>
    </div>
    <div class="category category-blue pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Caddy</a>
    </div>
    <div class="caddy-info" v-if="data?.dich_vu_caddy">
      <div class="caddy-info__content" data-aos="fade-up">
        <h3 class="title" data-aos="fade-up">
          {{ data.dich_vu_caddy[locale].name }}
        </h3>
        <div class="des" data-aos="fade-up">
          <p>
            {{ data.dich_vu_caddy[locale].description }}
          </p>
        </div>
        <div class="detail" data-aos="fade-up">
          {{ data.dich_vu_caddy[locale].content }}
          <p></p>
        </div>
      </div>
      <div class="caddy-info__bg" data-aos="fade-up">
        <img :src="data?.dich_vu_caddy?.background" alt="" />
        <div
          class="view-caddy"
          @click="activePopup(data?.danh_sach_caddy?.background)"
          data-aos="zoom-in"
        >
          <a href="/" onclick="return false;"
            >{{ data?.danh_sach_caddy[locale]?.name }}
          </a>
        </div>
      </div>
    </div>
    <div class="caddy-ratings" v-if="data?.caddy_hang_dau_cua_thang">
      <h1 class="title" data-aos="fade-up">
        {{ data.caddy_hang_dau_cua_thang[locale].name }}
      </h1>
      <div class="des" data-aos="fade-up">
        <p>
          {{ data.caddy_hang_dau_cua_thang[locale].description }}
        </p>
      </div>
      <div class="info-ratings">
        <div
          data-aos="fade-up"
          v-for="(item, index) in caddys"
          :key="index"
          :class="[
            caddys.length <= 2 ? '' : getClass(caddys.length, index+1),
            'item',
          ]"
        >
          <div class="item__avatar">
            <img :src="item?.image" alt="" />
          </div>
          <div class="item__name">{{ item?.full_name }}</div>
          <div class="item__number-caddy">{{ trans('frontend.number_caddy') }}: {{ item?.number }}</div>
          <div class="item__exp" v-if="item?.experience">
            {{ trans('frontend.experience') }}: {{ item?.experience }} {{ trans('frontend.year') }}
          </div>
          <div class="item__booking" v-if="item?.booking_in_month">
            {{trans('frontend.booking_in_month')}}: {{ item?.booking_in_month }} {{ trans('frontend.turn') }}
          </div>
          <div class="item__star">
            <div
              class="icon"
              v-for="(star, index2) in item?.star"
              :key="index2"
            >
              <img src="../assets/img/caddy/star.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <popupImage :img="img" />
  </div>
</template>
<script>
/* global $ */
import popupImage from "@/components/popup/popupImage.vue";
import CourseService from "@/services/CourseService";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { popupImage },
  data() {
    return {
      img: "",
      caddys: [],
      data: "",
      headMenu: {
        main: 0,
        sub: 2,
      },
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.dataCaddy();
    this.listCaddy();
    this.setMenu(this.headMenu);
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    ...mapActions(["setMenu"]),
    activePopup(img) {
      this.img = img;
      $("#popupcommon").removeClass("hide");
      $("#popupcommon").addClass("active");
    },
    async dataCaddy() {
      await CourseService.informationCaddy()
        .then((resp) => {
          this.data = resp.data.data;
        })
        .catch(() => {});
    },
    async listCaddy() {
      await CourseService.getListCaddy()
        .then((resp) => {
          this.caddys = resp.data.data;
        })
        .catch(() => {});
    },
    getClass(lenght, index) {
      switch (lenght) {
        case 3:
        case 4:
        case 5:
          console.log('vao4');
          return index === 1 ? "item-100" : "";
        case 6:
        case 7:
          if (index === 1) {
            return "item-100";
          } else if (index === 2 || index === 3) {
            return "item-50";
          } else {
            return "";
          }
        case 8:
          if (index === 1) {
            return "item-100";
          } else if (index === 2 || index === 3 || index === 4) {
            return "item-30";
          } else {
            return "";
          }
        default:
          return "";
      }
    },
  },
};
</script>
